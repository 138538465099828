import React, { Fragment } from 'react';

import Styled from 'styled-components';

import format from 'date-fns/format';

import { Table } from '../styles/tables';

const EmploymentApplicationDetails = ({ selectedApplication }) => {
  if (!selectedApplication) {
    return <Fragment />;
  }
  return (
    <Wrapper>
      <span style={{ marginRight: '12px' }}>
        <strong>Applied at:</strong>
      </span>
      <span>{format(selectedApplication.createdAt, 'MMM D, YYYY h:mm A')}</span>
      <h3>Contact information</h3>
      <dl>
        <dt>Email address</dt>
        <dd>
          {selectedApplication.emailAddress
            ? selectedApplication.emailAddress
            : ''}
        </dd>
      </dl>
      <h3>
        <strong>Phone numbers</strong>
      </h3>
      {selectedApplication &&
      selectedApplication.phoneNumbers &&
      selectedApplication.phoneNumbers.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Number type</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            {selectedApplication.phoneNumbers.map(number => (
              <tr key={number.number}>
                <td>{number.numberType}</td>
                <td>{number.number}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h4 style={{ color: '#999' }}>No phone numbers to display</h4>
      )}
      <h3>
        <strong>Address</strong>
      </h3>
      {selectedApplication &&
        selectedApplication.address &&
        selectedApplication.address.streetAddress}
      <br />
      {selectedApplication &&
      selectedApplication.address &&
      selectedApplication.address.streetAddressTwo
        ? `${selectedApplication.address.streetAddressTwo}<br />`
        : ''}
      {`${selectedApplication &&
        selectedApplication.address &&
        selectedApplication.address.city} ${selectedApplication &&
        selectedApplication.address &&
        selectedApplication.address.state}, ${selectedApplication &&
        selectedApplication.address &&
        selectedApplication.address.zipCode}`}
      <h3>Availability</h3>
      <dl>
        <dt>Available to start</dt>
        <dd>{format(selectedApplication.firstDayAvailable, 'MMMM Do YYYY')}</dd>
        <br />
        <dt>Available any time throughout the day</dt>
        <dd>{selectedApplication.availableAnyTime ? 'Yes' : 'No'}</dd>
        <br />
        <h3>Days availabile</h3>
        <dt>Monday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.monday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Tuesday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.tuesday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Wednesday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.wednesday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Thursday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.thursday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Friday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.friday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Saturday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.saturday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
        <dt>Sunday</dt>
        <dd>
          {selectedApplication &&
          selectedApplication.daysAvailable &&
          selectedApplication.daysAvailable.sunday
            ? 'Yes'
            : 'No'}
        </dd>
        <br />
      </dl>
      <h3>Education</h3>
      <dl>
        <dt>Graduated high school</dt>
        <dd>{selectedApplication.highSchoolGedOrDiploma ? 'Yes' : 'No'}</dd>
        <br />
        <dt>Graduated college</dt>
        <dd>{selectedApplication.graduatedCollege ? 'Yes' : 'No'}</dd>
        <br />
        <dt>Currently in college</dt>
        <dd>{selectedApplication.currentlyInCollege ? 'Yes' : 'No'}</dd>
        <br />
        <dt>Education history</dt>
        <dd>{selectedApplication.educationHistory}</dd>
        <br />
      </dl>
      <h3>Miscellaneous</h3>
      <dl>
        <dt>Authorized to work in the U.S.</dt>
        <dd>{selectedApplication.authorizedToWork ? 'Yes' : 'No'}</dd>
        <br />
        <dt>Full-time or part-time preferred</dt>
        <dd>
          {selectedApplication.fullTimeOrPartTimePreferred === 'full'
            ? 'Full time'
            : 'Part time'}
        </dd>
        <br />
        <dt>Ever convicted of a felony</dt>
        <dd>{selectedApplication.convictedOfFelony ? 'Yes' : 'No'}</dd>
        <br />
        {selectedApplication.convictedOfFelony && (
          <Fragment>
            <dt>Felony explanation</dt>
            <dd>{selectedApplication.felonyExplanation}</dd>
            <br />
          </Fragment>
        )}
        <dt>Clean driving record</dt>
        <dd>{selectedApplication.cleanDrivingRecord ? 'Yes' : 'No'}</dd>
        <br />
        <dt>Skills</dt>
        <dd>{selectedApplication.skills}</dd>
        <br />
        <dt>References</dt>
        <dd>{selectedApplication.references}</dd>
      </dl>
    </Wrapper>
  );
};

const Wrapper = Styled.div`
  dl {
    dt {
      display: inline-block;
      font-weight: bold;
    }

    dd {
      display: inline-block;
      margin-bottom: 6px;
    }
  }
`;

export default EmploymentApplicationDetails;
