import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import format from 'date-fns/format';
import startCase from 'lodash/startCase';

import Styled, { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';

import Card from '../primespot-ui/components/card';
import Gravatar from '../primespot-ui/components/gravatar';
import Toggle from '../primespot-ui/components/toggle';
import Modal from '../primespot-ui/components/modal';
import SmartForm from './smartForm';

import {
  TextareaInput,
  PrimaryButton,
  SelectInput,
  Label,
  SecondaryButton,
} from '../styles/forms';

import config from '../config';

class LeadCard extends Component {
  state = {
    lead: null,
    notes: [],
    sendingEmail: false,
  };

  static propTypes = {
    apiUrl: PropTypes.string.isRequired,
    leadId: PropTypes.string.isRequired,
    headers: PropTypes.object,
    // User object for documenting notes.
    user: PropTypes.object,
    // Notification function.
    notify: PropTypes.func,
    // Schema to pass to Smart Form.
    schema: PropTypes.array,
    // Request to close the containing modal.
    requestClose: PropTypes.func,
    // Callback for when top-level lead data changes (not notes).
    onLeadDataChange: PropTypes.func,
    // Callback for when notes data changes.
    onNotesChange: PropTypes.func,
    role: PropTypes.string.isRequired,
    isAllowed: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.location.hash = 'lead-card';
    window.addEventListener('hashchange', this.hashChangeListener);
    this.populateLead();
    this.populateNotes();
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.hashChangeListener);
  }

  hashChangeListener = () => {
    if (window.location.hash === '') {
      this.props.requestClose();
    }
  };

  populateLead = () => {
    axios
      .get(`${this.props.apiUrl}/${this.props.leadId}`, {
        headers: this.props.headers,
      })
      .then(res => {
        this.setState({ lead: res.data });
      })
      .catch(err => {
        console.error(err);
      });
  };

  populateNotes = () => {
    axios
      .get(`${this.props.apiUrl}/${this.props.leadId}/notes`, {
        headers: this.props.headers,
      })
      .then(res => {
        this.setState({ notes: res.data });
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { role, isAllowed } = this.props;

    if (!this.state.lead || !isAllowed(role, 'leads', 'read')) {
      return <div />;
    }
    const {
      lead: {
        name = '',
        email = '',
        phoneNumber = '',
        streetAddress = '',
        city = '',
        state = '',
        zipCode = '',
        status = '',
        source = '',
        createdAt = '',
        details,
        blackbox,
      },
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Toggle>
            {({ toggled: showEditForm, toggle: toggleEditForm }) => (
              <Fragment>
                <TwoColumn>
                  <div className="column">
                    <Card>
                      {isAllowed(role, 'leads', 'update') && (
                        <SecondaryButton
                          style={{
                            position: 'absolute',
                            top: '6px',
                            right: '6px',
                          }}
                          onClick={toggleEditForm}
                        >
                          Edit
                        </SecondaryButton>
                      )}
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Gravatar
                          email={email}
                          size="60"
                          style={{
                            marginRight: '24px',
                            width: '60px',
                            height: '60px',
                          }}
                        />
                        <div>
                          <div style={{ marginBottom: '6px' }}>{name}</div>
                          <div>
                            <span style={{ fontSize: '90%', color: '#777' }}>
                              Status:
                            </span>
                            <span
                              style={{
                                color: '#209CEE',
                                backgroundColor: 'transparent',
                                display: 'inline-block',
                                padding: '6px 6px',
                                borderRadius: '2px',
                                marginLeft: '6px',
                              }}
                            >
                              {startCase(status)}
                            </span>
                          </div>
                          <div>
                            <span style={{ fontSize: '90%', color: '#777' }}>
                              Source:
                            </span>
                            <span
                              style={{
                                color: '#209CEE',
                                backgroundColor: 'transparent',
                                display: 'inline-block',
                                padding: '6px 6px',
                                borderRadius: '2px',
                                marginLeft: '6px',
                              }}
                            >
                              {startCase(source)}
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                marginRight: '6px',
                                lineHeight: '200%',
                                fontSize: '90%',
                                color: '#777',
                              }}
                            >
                              Lead acquired at:
                            </span>
                            <span>
                              {format(createdAt, 'MMM D, YYYY h:mm A')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      {isAllowed(role, 'leads', 'update') && (
                        <SecondaryButton
                          style={{
                            position: 'absolute',
                            top: '6px',
                            right: '6px',
                          }}
                          onClick={toggleEditForm}
                        >
                          Edit
                        </SecondaryButton>
                      )}
                      <h3>About {name}</h3>
                      <Toggle>
                        {({ toggle, toggled }) => (
                          <Fragment>
                            <div className="lead-card__entry">
                              <div className="lead-card__entry__label">
                                Email
                              </div>
                              <div className="lead-card__entry__value">
                                <a
                                  href="#show-email-form"
                                  onClick={ev => {
                                    ev.preventDefault();

                                    toggle();
                                  }}
                                >
                                  {email}
                                </a>
                              </div>
                            </div>
                            {toggled && (
                              <Modal onClose={toggle} header={`Email ${name}`}>
                                <div className="lead-email-form">
                                  <div id="lead-email-form__to">
                                    To: {email}
                                  </div>
                                  <div id="lead-email-form__subject">
                                    <label>
                                      <span>Subject:</span>
                                      <input
                                        id="lead-email-form__subject-input"
                                        type="text"
                                      />
                                    </label>
                                  </div>
                                  <div id="lead-email-form__message">
                                    <textarea
                                      id="lead-email-form__message-input"
                                      placeholder="Type your message here..."
                                    />
                                  </div>
                                  <PrimaryButton
                                    style={{ marginRight: '0' }}
                                    disabled={this.state.sendingEmail}
                                    onClick={() => {
                                      const subjectInput = document.querySelector(
                                        '#lead-email-form__subject-input'
                                      );
                                      const messageInput = document.querySelector(
                                        '#lead-email-form__message-input'
                                      );

                                      this.setState({ sendingEmail: true });
                                      axios
                                        .post(`${config.backendServer}/email`, {
                                          toEmail: email,
                                          fromEmail: this.props.user.email,
                                          subject: subjectInput.value,
                                          message: messageInput.value,
                                        })
                                        .then(res => {
                                          if (res.status === 200) {
                                            this.props.notify({
                                              type: 'success',
                                              message:
                                                'Email sent successfully.',
                                            });
                                          }
                                        })
                                        .catch(err => {
                                          this.props.notify({
                                            type: 'danger',
                                            message:
                                              'Something went wrong sending the email.  Please try again.',
                                          });
                                          console.group(
                                            'Error sending email to lead...'
                                          );
                                          console.error(err);
                                          console.groupEnd();
                                        })
                                        .finally(() => {
                                          this.setState({
                                            sendingEmail: false,
                                          });
                                        });
                                    }}
                                  >
                                    Send email
                                  </PrimaryButton>
                                </div>
                              </Modal>
                            )}
                          </Fragment>
                        )}
                      </Toggle>
                      <div className="lead-card__entry">
                        <div className="lead-card__entry__label">
                          Phone number
                        </div>
                        <div className="lead-card__entry__value">
                          {phoneNumber}
                        </div>
                      </div>
                      <div className="lead-card__entry">
                        <div className="lead-card__entry__label">Address</div>
                        <div className="lead-card__entry__value">
                          {streetAddress}
                          {streetAddress && <br />}
                          {`${city}${city ? ',' : ''} ${state} ${zipCode}`}
                        </div>
                      </div>
                    </Card>
                    {(details || blackbox) && (
                      <Card>
                        {details && (
                          <div className="lead-card__entry">
                            <div className="lead-card__entry__label">
                              Details
                            </div>
                            <div className="lead-card__entry__value">
                              {details}
                            </div>
                          </div>
                        )}
                        {blackbox && Object.keys(blackbox).length > 0 && (
                          <Fragment>
                            <h4>Additional Information</h4>
                            {Object.keys(blackbox).map((key, index) => (
                              <div className="lead-card__entry" key={index}>
                                <div className="lead-card__entry__label">
                                  {startCase(key)}
                                </div>
                                <div className="lead-card__entry__value">
                                  {blackbox[key]}
                                </div>
                              </div>
                            ))}
                          </Fragment>
                        )}
                      </Card>
                    )}
                  </div>
                  <div className="column">
                    {isAllowed(role, 'leads', 'update') && (
                      <Card>
                        <h3>New note</h3>
                        <AddNoteSection
                          user={this.props.user}
                          apiUrl={this.props.apiUrl}
                          leadId={this.props.leadId}
                          headers={this.props.headers}
                          onNoteSubmit={this.populateNotes}
                        />
                      </Card>
                    )}
                    <h3 style={{ marginTop: '24px' }}>Notes</h3>
                    {this.state.notes.length === 0 && (
                      <div
                        style={{
                          marginTop: '18px',
                          color: '#BBB',
                          fontSize: '24px',
                        }}
                      >
                        There aren't any notes to display.
                      </div>
                    )}
                    {this.state.notes.map(note => {
                      if (!note.creator) {
                        return <div key={note._id} />;
                      }
                      return (
                        <Card key={note._id}>
                          <NoteWrapper>
                            <div className="note-wrapper__meta">
                              <strong>{`${note.creator.firstName} ${
                                note.creator.lastName
                              }`}</strong>{' '}
                              created this note on{' '}
                              <strong>
                                {format(note.createdAt, 'h:mm a MMMM Do YYYY')}
                              </strong>
                            </div>
                            <div className="note-wrapper__category">
                              {startCase(note.category)}
                            </div>
                            <div className="note-wrapper__note">
                              {note.note}
                            </div>
                          </NoteWrapper>
                        </Card>
                      );
                    })}
                  </div>
                </TwoColumn>
                {showEditForm && (
                  <Modal onClose={toggleEditForm} header="Edit Lead">
                    <SmartForm
                      schema={this.props.schema}
                      dataId={this.props.leadId}
                      apiUrl={this.props.apiUrl}
                      headers={this.props.headers}
                      notify={this.props.notify}
                      onDataChange={() => {
                        this.populateLead();
                        this.props.onLeadDataChange &&
                          this.props.onLeadDataChange();
                      }}
                      enableDelete={true}
                      onDelete={() => {
                        toggleEditForm();
                        this.props.onLeadDataChange &&
                          this.props.onLeadDataChange();
                        this.props.requestClose();
                      }}
                    />
                  </Modal>
                )}
              </Fragment>
            )}
          </Toggle>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  a {
    color ${({ theme }) => theme.primary.colors.darkBlue.base};
    transition: all .3s ease;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    }
  }

  h3 {
    margin-top: 0;
  }

  .lead-card__entry {
    margin-bottom: 24px;
  }

  .lead-card__entry__label {
    color: #777;
    font-size: 80%;
    margin-bottom: 6px;
  }

  .lead-card__entry__value {
    color: #333;
    word-wrap: break-word; 
  }

  .lead-email-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    #lead-email-form__to {
      margin-bottom: 12px;
    }

    #lead-email-form__subject {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      margin-bottom: 12px;

      label {
        display: flex;
        align-items: center;
        width: 100%;

        span {
          display: inline-block;
          margin-right: 6px;
        }
      }
    }
    
    #lead-email-form__subject-input {
      flex-grow: 1;
      padding: 6px;
      border: none;
    }
    
    #lead-email-form__message {
      width: 100%;
      display: flex;
      flex-grow: 1;
    }
    
    #lead-email-form__message-input {
      width: 100%;
      height: 100%;
      border: none;
      padding: 6px;
      box-sizing: border-box;
      resize: none;
    }
  }

  @media screen and (max-width: 540px) {
    .ps-card {
      padding-top: 48px;
    }
  }
`;

class AddNoteSection extends Component {
  state = {
    note: '',
    category: 'other',
  };

  handleNoteChange = ev => {
    const event = ev;
    this.setState({ note: event.target.value });
  };

  handleCategoryChange = ev => {
    const event = ev;
    this.setState({ category: event.target.value });
  };

  handleNoteSubmit = ev => {
    const event = ev;
    event.preventDefault();
    const data = {
      creator: this.props.user._id,
      note: this.state.note,
      category: this.state.category,
    };
    axios
      .post(`${this.props.apiUrl}/${this.props.leadId}/notes`, data, {
        headers: this.props.headers,
      })
      .then(res => {
        this.props.onNoteSubmit && this.props.onNoteSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    return (
      <AddNoteSectionWrapper>
        <form onSubmit={this.handleNoteSubmit}>
          <Label text="Note category">
            <SelectInput
              value={this.state.category}
              onChange={this.handleCategoryChange}
            >
              <option value="other">Other</option>
              <option value="call log">Call log</option>
              <option value="email log">Email log</option>
              <option value="note">Note</option>
            </SelectInput>
          </Label>
          <TextareaInput
            placeholder="Enter note here..."
            value={this.state.note}
            onChange={this.handleNoteChange}
          />
          <PrimaryButton type="submit">Save note</PrimaryButton>
        </form>
      </AddNoteSectionWrapper>
    );
  }
}

const AddNoteSectionWrapper = Styled.div`

`;

const NoteWrapper = Styled.div`
  .note-wrapper__meta {
    margin-bottom: 24px;
  }

  .note-wrapper__category {
    color: #777;
    color: white;
    font-size: 90%;
    margin-bottom: 18px;
    background-color: #209CEE;
    padding: 6px 12px;
    border-radius: 2px;
    display: inline-block;
  }

  .note-wrapper__note {
    white-space: pre-wrap;
  }
`;

const TwoColumn = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;

  & > .column {
    box-sizing: border-box;
    width: calc(50% - 24px);
  }

  & > .column:first-child {
    margin-right: 24px;
  }

  @media screen and (max-width: 920px) {
    & > .column {
      width: 100%;
    }

    & > .column:first-child {
      margin-right: 0;
    }
  }
`;

export default LeadCard;
