import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Toggle from '../primespot-ui/components/toggle';
import SmartTable from './smartTable';
import SmartForm from './smartForm';
import Modal from '../primespot-ui/components/modal';
import LeadCard from './leadCard';

import config from '../config';
import matchMediaCheck from '../util/matchMediaCheck';

const leadsSchema = [
  {
    name: 'name',
    dataType: 'string',
    label: 'Name',
    type: 'text',
    placeholder: 'John Smith',
  },
  {
    name: 'email',
    dataType: 'string',
    label: 'Email address',
    type: 'email',
    placeholder: 'john.smith@email.com',
  },
  {
    name: 'phoneNumber',
    dataType: 'string',
    label: 'Phone number',
    type: 'tel',
    placeholder: '540-555-1212',
  },
  {
    name: 'zipCode',
    dataType: 'string',
    label: 'Zip code',
    type: 'text',
    placeholder: '12345',
  },
  {
    name: 'status',
    dataType: 'select',
    label: 'Status',
    placeholder: 'Status',
    options: [
      {
        value: 'new',
        label: 'New',
        selected: true,
      },
      {
        value: 'prospect',
        label: 'Prospect',
      },
      {
        value: 'warm',
        label: 'Warm',
      },
      {
        value: 'hot',
        label: 'Hot',
      },
      {
        value: 'won',
        label: 'Won',
      },
      {
        value: 'lost',
        label: 'Lost',
      },
      {
        value: 'archived',
        label: 'Archived',
      },
    ],
  },
  {
    name: 'source',
    dataType: 'string',
    label: 'Lead source',
    type: 'text',
  },
];

class LeadsTable extends Component {
  state = {
    selectedLead: null,
    data: null,
    desktopScreen: true,
  };

  static propTypes = {
    headers: PropTypes.object,
    // Send a notification message.
    notify: PropTypes.func,
    user: PropTypes.object,
    role: PropTypes.string,
    isAllowed: PropTypes.func,
  };

  static defaultProps = {
    headers: {},
  };

  componentDidMount() {
    this._isMounted = true;
    this.MatchMediaCheck = matchMediaCheck('720px');
    this._isMounted &&
      this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
    this.MatchMediaCheck.registerResizeListener(this.checkDesktopScreen);
  }

  componentWillUnmount() {
    this.MatchMediaCheck.removeResizeListener(this.checkDesktopScreen);
  }

  checkDesktopScreen = () => {
    this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
  };

  onDataChange = () => {
    axios
      .get(`${config.backendServer}/leads`, {
        headers: this.props.headers,
      })
      .then(res => {
        const data = res.data.results.filter(d => d.status !== 'archived');
        this.setState({ data });
      })
      .catch(err => {
        if (err.response.status === 401) {
          // Unauthorized.
          console.error(
            "You are not authorized to perform this action.  Perhaps you aren't logged in or forgot to pass headers to the SmartTable component."
          );
        } else {
          console.error(err);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const leadsColumns = [
      {
        Header: 'ID',
        accessor: '_id',
        show: false,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
        show: this.state.desktopScreen === true ? true : false,
      },
      {
        Header: 'Phone number',
        accessor: 'phoneNumber',
        show: this.state.desktopScreen === true ? true : false,
      },
      {
        Header: 'Zip code',
        accessor: 'zipCode',
        show: this.state.desktopScreen === true ? true : false,
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ];

    const { role, isAllowed } = this.props;

    return (
      <Toggle>
        {({ toggled: showLeadCard, toggle: toggleLeadCard }) => (
          <Fragment>
            <Toggle>
              {({ toggled: showLeadAddForm, toggle: toggleLeadAddForm }) => (
                <Fragment>
                  <h2>Leads</h2>
                  <SmartTable
                    data={this.state.data}
                    columns={leadsColumns}
                    apiUrl={`${config.backendServer}/leads`}
                    headers={this.props.headers}
                    mapDataToTableData={data => {
                      return data.filter(d => d.status !== 'archived');
                    }}
                    onRowClick={data => {
                      this.setState({ selectedLead: data._id });
                      toggleLeadCard();
                    }}
                    enableAddButton={isAllowed(role, 'leads', 'create')}
                    addButtonText="Add lead"
                    onAddButtonClick={() => {
                      toggleLeadAddForm();
                    }}
                  />
                  {showLeadCard && (
                    <Modal
                      onClose={toggleLeadCard}
                      background="#FAFAFA"
                      header="Lead Info"
                    >
                      <LeadCard
                        leadId={this.state.selectedLead}
                        apiUrl={`${config.backendServer}/leads`}
                        headers={this.props.headers}
                        user={this.props.user}
                        notify={this.props.notify}
                        schema={leadsSchema}
                        requestClose={toggleLeadCard}
                        onLeadDataChange={this.onDataChange}
                        role={role}
                        isAllowed={isAllowed}
                      />
                    </Modal>
                  )}
                  {showLeadAddForm && (
                    <Modal onClose={toggleLeadAddForm} header="Add Lead">
                      <SmartForm
                        schema={leadsSchema}
                        apiUrl={`${config.backendServer}/leads`}
                        headers={this.props.headers}
                        notify={this.props.notify}
                        onDataChange={this.onDataChange}
                        enableDelete={isAllowed(role, 'leads', 'delete')}
                        enableSave={isAllowed(role, 'leads', 'update')}
                      />
                    </Modal>
                  )}
                </Fragment>
              )}
            </Toggle>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

export default LeadsTable;
