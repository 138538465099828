import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Toggle from '../primespot-ui/components/toggle';
import Modal from '../primespot-ui/components/modal';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Styled from 'styled-components';
import axios from 'axios';
import EmploymentApplicationDetails from './employmentApplicationDetails';

import config from '../config';
import matchMediaCheck from '../util/matchMediaCheck';

class EmploymentApplicationsTable extends Component {
  state = {
    loading: true,
    tableData: [],
    selectedApplication: null,
  };

  static propTypes = {
    headers: PropTypes.object,
    // Send a notification message.
    notify: PropTypes.func,
    // The role of the user.
    role: PropTypes.string,
    // Checks authorization.
    isAllowed: PropTypes.func,
  };

  static defaultProps = {
    headers: {},
  };

  componentDidMount() {
    this._isMounted = true;
    this.MatchMediaCheck = matchMediaCheck('720px');
    this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
    this.MatchMediaCheck.registerResizeListener(this.checkDesktopScreen);

    axios
      .get(`${config.backendServer}/employment-applications`, {
        headers: this.props.headers,
        params: {
          test: 'test',
        },
      })
      .then(res => {
        this._isMounted &&
          this.setState({
            tableData: Array.isArray(res.data.results) ? res.data.results : [],
          });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.MatchMediaCheck.removeResizeListener(this.checkDesktopScreen);
  }

  checkDesktopScreen = () => {
    this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
  };

  shouldPaginationShow = () => {
    if (this.state.loading) return false;
    if (this.state.tableData.length > 10) return true;
    return false;
  };

  render() {
    const employmentApplicationsTableColumns = [
      {
        Header: 'ID',
        accessor: '_id',
        show: false,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        show: this.state.desktopScreen === true ? true : false,
      },
    ];

    const { role, isAllowed } = this.props;
    const { selectedApplication } = this.state;

    if (!isAllowed(role, 'employment-applications', 'read')) {
      return <div />;
    }

    return (
      <Wrapper>
        <Toggle>
          {({ toggled: showApplication, toggle: toggleApplication }) => (
            <Fragment>
              <ReactTable
                className="-highlight"
                defaultPageSize={10}
                minRows={3}
                loading={this.state.loading}
                columns={employmentApplicationsTableColumns}
                data={this.state.tableData}
                showPagination={this.shouldPaginationShow()}
                showPageSizeOptions={false}
                showPageJump={false}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.setState(
                        { selectedApplication: rowInfo.row._original },
                        () => {
                          toggleApplication();
                        }
                      );

                      if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                  };
                }}
              />
              {showApplication && (
                <Modal
                  onClose={toggleApplication}
                  header={`${
                    selectedApplication.firstName
                      ? selectedApplication.firstName
                      : ''
                  } ${
                    selectedApplication.middleName
                      ? selectedApplication.middleName
                      : ''
                  } ${
                    selectedApplication.lastName
                      ? selectedApplication.lastName
                      : ''
                  }`}
                >
                  <EmploymentApplicationDetails
                    selectedApplication={selectedApplication}
                  />
                </Modal>
              )}
            </Fragment>
          )}
        </Toggle>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: solid 1px rgba(0,0,0,0.05) !important;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: bold;
    font-size: 16px;
  }

  .table__add-button {
    background-color: #01D1B2;
    color: white;
    padding: 12px 24px;
    margin-bottom: 12px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;

    &:hover {
      background-color: #21E1D2;
    }
  }

  .rt-table {
    
  }

  .rt-tr {
    height: 40px;
    align-items: center;
    cursor: pointer;
  }

  .rt-th, .rt-td {
    padding-left: 24px !important;
  }

  .rt-th {
    text-align: left;
    cursor: auto !important;
  }

  dl {
    dt {
      display: inline-block;
      font-weight: bold;
    }

    dd {
      display: inline-block;
      margin-bottom: 6px;
    }
  }
`;

export default EmploymentApplicationsTable;
