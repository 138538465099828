import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { css } from 'styled-components';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../../redux/actions/authentication/actionCreators';

import { growl } from '../../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../../redux/actions/authorization/actionCreators';

import axios from 'axios';

import Layout from '../../layouts/backendLayout';

import LoginForm from '../../components/loginForm';
import SearchInput from '../../components/searchInput';
import LeadsTable from '../../components/leadsTable';
import LeadCard from '../../components/leadCard';
import EmploymentApplicationsTable from '../../components/employmentApplicationsTable';
import EmploymentApplicationDetail from '../../components/employmentApplicationDetails';
import Modal from '../../primespot-ui/components/modal';
import NotificationProvider from '../../components/notificationProvider';

import { Wrapper } from '../../styles/pages/backend/homepage';

import config from '../../config';

const leadsSchema = [
  {
    name: 'name',
    dataType: 'string',
    label: 'Name',
    type: 'text',
    placeholder: 'John Smith',
  },
  {
    name: 'email',
    dataType: 'string',
    label: 'Email address',
    type: 'email',
    placeholder: 'john.smith@email.com',
  },
  {
    name: 'phoneNumber',
    dataType: 'string',
    label: 'Phone number',
    type: 'tel',
    placeholder: '540-555-1212',
  },
  {
    name: 'zipCode',
    dataType: 'string',
    label: 'Zip code',
    type: 'text',
    placeholder: '12345',
  },
  {
    name: 'status',
    dataType: 'select',
    label: 'Status',
    placeholder: 'Status',
    options: [
      {
        value: 'new',
        label: 'New',
        selected: true,
      },
      {
        value: 'prospect',
        label: 'Prospect',
      },
      {
        value: 'warm',
        label: 'Warm',
      },
      {
        value: 'hot',
        label: 'Hot',
      },
      {
        value: 'won',
        label: 'Won',
      },
      {
        value: 'lost',
        label: 'Lost',
      },
      {
        value: 'archived',
        label: 'Archived',
      },
    ],
  },
  {
    name: 'source',
    dataType: 'string',
    label: 'Lead source',
    type: 'text',
  },
];

class BackendPage extends Component {
  state = {
    selectedUser: null,
    showSearchDropdown: false,
    searchLoading: false,
    searchResults: [],
    selectedEmploymentApplication: null,
    selectedLead: null,
  };

  displaySearchResultData = result => {
    switch (result.source) {
      case 'Employment application':
        return `${result.firstName} ${result.lastName}`;
      case 'Lead':
        return `${result.name}`;
      default:
        return;
    }
  };

  handleSearchResultClick = result => {
    const token = this.props.getToken();
    switch (result.source) {
      case 'Employment application':
        axios
          .get(
            `${config.backendServer}/employment-applications/${result._id}`,
            {
              headers: { authorization: `Token ${token}` },
            }
          )
          .then(application => {
            this.setState({ selectedEmploymentApplication: application.data });
          })
          .catch(err => {
            console.error(err);
          });
        break;
      case 'Lead':
        axios
          .get(`${config.backendServer}/leads/${result._id}`, {
            headers: { authorization: `Token ${token}` },
          })
          .then(lead => {
            this.setState({ selectedLead: lead.data });
          })
          .catch(err => {
            console.error(err);
          });
        break;
      default:
        return;
    }
  };

  render() {
    const {
      user,
      login,
      logout,
      getEmailToken,
      // tokenExists,
      // token,
      getToken,
      // allow,
      isAllowed,
      growl,
    } = this.props;

    return (
      <Layout
        user={user}
        login={login}
        getEmailToken={getEmailToken}
        logout={logout}
        growl={growl}
        location={this.props.location}
      >
        <NotificationProvider />
        <Wrapper>
          {user && ['sales', 'admin', 'super admin'].includes(user.role) ? (
            <Fragment>
              <SearchWidget
                showSearchDropdown={this.state.showSearchDropdown}
                searchLoading={this.state.searchLoading}
                onQueryDelete={() => {
                  this.setState({ showSearchDropdown: false });
                }}
                onOutsideClick={() => {
                  this.setState({ showSearchDropdown: false });
                }}
                onInput={query => {
                  this.setState({ searchLoading: true });
                  Promise.all([
                    axios.get(
                      `${
                        config.backendServer
                      }/employment-applications/search?query=${query}`,
                      {
                        headers: { authorization: `Token ${getToken()}` },
                      }
                    ),
                    axios.get(
                      `${config.backendServer}/leads/search?query=${query}`,
                      {
                        headers: { authorization: `Token ${getToken()}` },
                      }
                    ),
                  ])
                    .then(results => {
                      this.setState({ showSearchDropdown: true });
                      let data = [];
                      results.forEach(result => {
                        data = data.concat(result.data);
                      });
                      this.setState({ searchResults: data });
                    })
                    .catch(err => {
                      console.error(err);
                    })
                    .finally(() => {
                      this.setState({ searchLoading: false });
                    });
                }}
                searchResults={this.state.searchResults}
                handleSearchResultClick={this.handleSearchResultClick}
                displaySearchResultData={this.displaySearchResultData}
              />
              {/*isAllowed(user.role, 'users', 'read') && (
                <UsersTable
                  headers={{ authorization: `Token ${getToken()}` }}
                  notify={growl}
                  role={user.role}
                  isAllowed={isAllowed}
                />
              )*/}
              {isAllowed(user.role, 'leads', 'read') && (
                <LeadsTable
                  headers={{ authorization: `Token ${getToken()}` }}
                  notify={growl}
                  user={user}
                  role={user.role}
                  isAllowed={isAllowed}
                />
              )}
              {isAllowed(user.role, 'employment-applications', 'read') && (
                <Fragment>
                  <h2>Employment Applications</h2>
                  <EmploymentApplicationsTable
                    headers={{ authorization: `Token ${getToken()}` }}
                    notify={growl}
                    user={user}
                    role={user.role}
                    isAllowed={isAllowed}
                  />
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <LoginForm />
            </Fragment>
          )}
          {this.state.selectedEmploymentApplication && (
            <Modal
              header={`${
                this.state.selectedEmploymentApplication.firstName
                  ? this.state.selectedEmploymentApplication.firstName
                  : ''
              } ${
                this.state.selectedEmploymentApplication.middleName
                  ? this.state.selectedEmploymentApplication.middleName
                  : ''
              } ${
                this.state.selectedEmploymentApplication.lastName
                  ? this.state.selectedEmploymentApplication.lastName
                  : ''
              }`}
              onClose={() => {
                this.setState({ selectedEmploymentApplication: null });
              }}
            >
              <EmploymentApplicationDetail
                selectedApplication={this.state.selectedEmploymentApplication}
              />
            </Modal>
          )}
          {this.state.selectedLead && (
            <Modal
              header="Lead Info"
              onClose={() => {
                this.setState({ selectedLead: null });
              }}
              background="#FAFAFA"
            >
              <LeadCard
                leadId={this.state.selectedLead._id}
                apiUrl={`${config.backendServer}/leads`}
                headers={{ authorization: `Token ${getToken()}` }}
                user={user}
                notify={growl}
                schema={leadsSchema}
                requestClose={() => {
                  this.setState({ selectedLead: null });
                }}
                onLeadDataChange={() => {}}
                role={user.role}
                isAllowed={isAllowed}
              />
            </Modal>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

const SearchWidget = ({
  showSearchDropdown,
  searchLoading,
  onQueryDelete,
  onOutsideClick,
  onInput,
  searchResults,
  handleSearchResultClick,
  displaySearchResultData,
}) => {
  return (
    <label style={{ display: 'inline-block' }}>
      <span style={{ display: 'inline-block', marginBottom: '6px' }}>
        Search
      </span>
      <SearchInput
        showDropdown={showSearchDropdown}
        loading={searchLoading}
        onQueryDelete={onQueryDelete}
        onOutsideClick={onOutsideClick}
        onInput={onInput}
        dropdownContent={
          <Fragment>
            {searchResults.length > 0 ? (
              <ul>
                {searchResults.map(result => (
                  <li
                    key={result._id}
                    onClick={() => {
                      handleSearchResultClick(result);
                    }}
                  >
                    <span className="search-result-source">
                      {result.source}
                    </span>
                    <span className="search-result-data">
                      {displaySearchResultData(result)}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p
                style={{
                  boxSizing: 'border-box',
                  paddingLeft: '6px',
                }}
              >
                No search results
              </p>
            )}
          </Fragment>
        }
        dropdownStylesMixin={dropdownStylesMixin}
      />
    </label>
  );
};

const dropdownStylesMixin = css`
  background: white;
  z-index: 9;
  max-height: 215px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
  }

  li {
    padding: 12px 6px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0 !important;
  }

  li:hover {
    background-color: rgb(240, 240, 240);
    width: 100%;
  }

  .search-result-source {
    display: inline-block;
    width: 40%;
    color: #777;
    border-right: 1px solid rgb(220, 220, 220);
    padding-right: 6px;
    box-sizing: border-box;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-result-data {
    display: inline-block;
    box-sizing: border-box;
    padding-left: 6px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackendPage);
