import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Styled, { ThemeProvider } from 'styled-components';

import SVG from '../primespot-ui/components/svg';

import theme from '../styles/theme';

class SearchInput extends Component {
  state = {
    query: '',
    freshInputCount: 0,
  };

  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    // Number of inputs required before calling callbacks.
    throttle: PropTypes.number,
    showDropdown: PropTypes.bool,
    dropdownContent: PropTypes.node,
    // Styled-components mixin to style the dropdown
    dropdownStylesMixin: PropTypes.array,
    // Callback after throttle count has been surpassed.
    onInput: PropTypes.func,
    // Callback when query text is deleted.
    onQueryDelete: PropTypes.func,
    // Callback called when a click occurs outside of the input or dropdown.
    onOutsideClick: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: 'Type your search query here...',
    throttle: 3,
    showDropdown: false,
    dropdownContent: <Fragment />,
    onInput: () => {},
    onQueryDelete: () => {},
    onOutsideClick: () => {},
    loading: false,
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onOutsideClick);
  }

  onOutsideClick = ev => {
    if (!this.wrapperRef || !this.wrapperRef.current) {
      return;
    }

    if (!this.wrapperRef.current.contains(ev.target)) {
      this.props.onOutsideClick();
    }
  };

  onQueryChange = ev => {
    const value = ev.target.value;
    if (value.length === 0) {
      this.setState({ query: '', freshInputCount: 0 });
      this.props.onQueryDelete();
      return;
    }

    this.setState(
      prevState => ({
        query: value,
        freshInputCount: prevState.freshInputCount + 1,
      }),
      () => {
        if (this.state.freshInputCount >= this.props.throttle) {
          this.props.onInput(this.state.query);
          this.setState({ freshInputCount: 0 });
        }
      }
    );
  };

  onKeyDown = ev => {
    if (ev.keyCode === 13) {
      this.setState({ freshInputCount: 0 });
      this.props.onInput(this.state.query);
    }
  };

  render() {
    const {
      className,
      placeholder,
      loading,
      dropdownContent,
      showDropdown,
      dropdownStylesMixin,
    } = this.props;
    const { query } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper
          ref={this.wrapperRef}
          className={
            className
              ? `ps__search__wrapper ${className}`
              : 'ps__search__wrapper'
          }
        >
          <SVG image="search" />
          <Input
            className="ps__search__input"
            type="text"
            placeholder={placeholder}
            value={query}
            onChange={this.onQueryChange}
            onKeyDown={this.onKeyDown}
            dropdownVisible={showDropdown}
          />
          {loading && <Loader className="ps__search__loading-spinner" />}
          <DropdownWrapper
            className="ps__search__dropdown"
            visible={showDropdown}
            dropdownStylesMixin={dropdownStylesMixin}
          >
            {dropdownContent}
          </DropdownWrapper>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  position: relative;
  box-sizing: border-box;
  width: 320px;
  height: 40px;

  .svg__icon {
    position: absolute;
    top: 50%;
    left: 9px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    fill: ${({ theme }) => theme.primary.colors.gray.darkest};
  }
`;

const Input = Styled.input`
  width: 100%;
  height: 100%;
  padding: 6px 30px 6px 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(230, 230, 230);
`;

const DropdownWrapper = Styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 6px);
  background: white;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-height: 240px;

  ${({ dropdownStylesMixin }) => dropdownStylesMixin}
`;

const Loader = Styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 6px;
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border: 3px solid #EEE;
  border-bottom: 3px solid #777;
  border-radius: 50%;
  -webkit-animation: spin 1s cubic-bezier(.75,.17,.23,.87) infinite; /* Safari */
  animation: spin 1s cubic-bezier(.75,.17,.23,.87) infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default SearchInput;
