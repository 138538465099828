import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

import axios from 'axios';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

/**
 * Automatically creates a table with pagination and sorting.
 *
 * Version: 0.0.1
 *
 * Todo
 * -----
 * - [ ] Ditch react-table and implement a custom table component.
 */
class SmartTable extends Component {
  state = {
    loading: true,
    tableData: [],
  };

  static propTypes = {
    // Providing a data set will override the normal behavior where
    // SmartTable fetches the data itself.  While the data prop is provided,
    // SmartTable behaves like a controlled component.
    data: PropTypes.array,
    headers: PropTypes.object,
    apiUrl: PropTypes.string.isRequired,
    // React-Table columns object.
    columns: PropTypes.array.isRequired,
    // A custom function for mapping the fetched API data to table format.
    // Accepts a data argument containing the data set.
    mapDataToTableData: PropTypes.func,
    // Callback function when a row is clicked.
    onRowClick: PropTypes.func,
    // False to disable the add button.  Defaults to true.
    enableAddButton: PropTypes.bool,
    // Custom text for the add button.
    addButtonText: PropTypes.string,
    // Callback when the add button is clicked.
    onAddButtonClick: PropTypes.func,
  };

  static defaultProps = {
    enableAddButton: true,
    addButtonText: 'Add',
  };

  componentDidMount() {
    this._isMounted = true;
    this.populateData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.populateData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.populateDataCancelToken &&
      this.populateDataCancelToken.cancel('Unmounting');
  }

  populateData = () => {
    if (!this.props.apiUrl) {
      return;
    }

    if (this.props.data) {
      if (this.props.mapDataToTableData) {
        this.setState({
          tableData: this.props.mapDataToTableData(this.props.data),
        });
      } else {
        this.setState({ tableData: this.props.data });
      }
    } else {
      this.populateDataCancelToken = axios.CancelToken.source();
      axios
        .get(`${this.props.apiUrl}`, {
          cancelToken: this.populateDataCancelToken.token,
          headers: this.props.headers,
        })
        .then(res => {
          if (
            this.props.mapDataToTableData &&
            res &&
            res.data &&
            res.data.results
          ) {
            this._isMounted &&
              this.setState({
                tableData: this.props.mapDataToTableData(res.data.results),
              });
          } else {
            const tableData = res.data && res.data.results;
            this._isMounted && this.setState({ tableData });
          }
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          if (err && err.response && err.response.status === 401) {
            // Unauthorized.
            console.error(
              "You are not authorized to perform this action.  Perhaps you aren't logged in or forgot to pass headers to the SmartTable component."
            );
          } else {
            console.error(err);
          }
        })
        .finally(() => {
          this._isMounted && this.setState({ loading: false });
        });
    }
  };

  shouldPaginationShow = () => {
    if (this.state.loading) return false;
    if (this.state.tableData.length > 10) return true;
    return false;
  };

  render() {
    return (
      <Wrapper>
        {this.props.enableAddButton && (
          <button
            className="table__add-button"
            onClick={this.props.onAddButtonClick}
          >
            {this.props.addButtonText}
          </button>
        )}
        <div className="smart-table__table-wrapper">
          <ReactTable
            className="-highlight"
            defaultPageSize={10}
            minRows={3}
            loading={this.state.loading}
            columns={this.props.columns}
            data={this.state.tableData}
            showPagination={this.shouldPaginationShow()}
            showPageSizeOptions={false}
            showPageJump={false}
            getTrProps={(state, rowInfo, column) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (this.props.onRowClick) {
                    this.props.onRowClick(rowInfo.row);
                  }

                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }}
          />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  .smart-table__table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }

  .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: solid 1px rgba(0,0,0,0.05) !important;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: bold;
    font-size: 16px;
  }

  .table__add-button {
    background-color: #01D1B2;
    color: white;
    padding: 12px 24px;
    margin-bottom: 12px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;

    &:hover {
      background-color: #21E1D2;
    }
  }

  .rt-table {
    
  }

  .rt-tr {
    height: 40px;
    align-items: center;
    cursor: pointer;
  }

  .rt-th, .rt-td {
    padding-left: 24px !important;
  }

  .rt-th {
    text-align: left;
    cursor: auto !important;
  }
`;

export default SmartTable;
