import Styled from 'styled-components';

const Wrapper = Styled.div`
  padding-right: 210px;

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 1080px) {
    padding-right: 0;
  }
`;

export { Wrapper };
